import React from 'react';
import cn from 'classnames';
import TextField from '@mui/material/TextField';

import { AdvancedFilters } from 'src/Context';

import Autocomplete from '../AutoComplete';

import css from './FiltersPopup.module.scss';

const roleBtns = [
  { title: 'אזרחים', name: 'אזרח' },
  { title: `צה"ל`, name: 'חייל' },
  { title: `משטרה`, name: 'שוטר' },
  { title: `כבאות`, name: 'כבאי' },
  { title: `שב"כ`, name: `שב"כ` },
];

interface Props {
  places: string[];
  units: string[];
  closePopup: () => void;
  activeFilters: null | Omit<AdvancedFilters, 'search'>;
  clearFilters: () => void;
  setFilters: (f: Omit<AdvancedFilters, 'search'>) => void;
}

const FiltersPopup = ({ places, units, closePopup, activeFilters, setFilters, clearFilters }: Props) => {
  const [role, setRole] = React.useState<string>('');
  const [place, setPlace] = React.useState<string>('');
  const [unit, setUnit] = React.useState<string>('');

  React.useEffect(() => {
    if (activeFilters) {
      setRole(activeFilters.role);
      setPlace(activeFilters.place);
      setUnit(activeFilters.unit);
    }
  }, [activeFilters]);

  const clickOnRole = (e: React.MouseEvent<HTMLDivElement>) => {
    const { name } = e.currentTarget.dataset;

    if (name) {
      if (name === role) {
        setRole('');
      } else {
        setRole(name);
      }
    }
  };

  const onChangePlace = (value: string) => {
    setPlace(value);
  };

  const onChangeUnit = (value: string) => {
    setUnit(value);
  };

  const clickOnApply = () => {
    const filters = {
      role: role,
      place: place,
      unit: unit,
    };

    setFilters(filters);
    closePopup();
  };

  return (
    <div className={css.popup}>
      <div className={css.overlay}></div>
      <div className={css.wrapper}>
        <div className={css.closeBtn} onClick={closePopup}></div>
        <div className={css.title}>
          סינון מתקדם
          <div
            className={cn(css.clearBtn, (role || place || unit) && css.active)}
            onClick={() => {
              clearFilters();
            }}
          >
            נקה
          </div>
        </div>
        <div className={css.fields}>
          <div className={css.fieldWrapper}>
            <div className={css.fieldTitle}>יישוב</div>
            <div className={css.filed}>
              <Autocomplete
                clearIcon={null}
                options={places}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => <li {...props}>{option}</li>}
                renderInput={(params) => <TextField {...params} placeholder="לדוגמה: שדרות" />}
                onSelectChange={onChangePlace}
                value={place || null}
              />
            </div>
          </div>
          <div className={css.fieldWrapper}>
            <div className={css.fieldTitle}>יחידה</div>
            <div className={css.filed}>
              <Autocomplete
                clearIcon={null}
                options={units}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => <li {...props}>{option}</li>}
                renderInput={(params) => <TextField {...params} placeholder="לדוגמה: גבעתי" />}
                onSelectChange={onChangeUnit}
                value={unit || null}
              />
            </div>
          </div>
          <div className={css.fieldWrapper}>
            <div className={css.fieldTitle}>בחרו</div>
            <div className={css.roleBtns}>
              {roleBtns.map((btn) => {
                return (
                  <div
                    className={cn(css.roleBtn, btn.name === role && css.active)}
                    key={btn.name}
                    data-name={btn.name}
                    onClick={clickOnRole}
                  >
                    {btn.title}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={css.applyBtnWrapper}>
          <div className={css.btnApply} onClick={clickOnApply}>
            סינון תוצאות
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersPopup;
