import React from 'react';

import { useAppState } from 'src/Context';

export const usePlacesAndUnits = () => {
  const { people } = useAppState();

  const { places, units } = React.useMemo(() => {
    const placesArr: string[] = [];
    const unitsArr: string[] = [];

    people.forEach((item) => {
      if (item.f && (item.f as string).trim() !== '') {
        placesArr.push((item.f as string).trim());
      }
    });

    people.forEach((item) => {
      if (item.k && (item.k as string).trim() !== '') {
        unitsArr.push((item.k as string).trim());
      }
    });

    return {
      places: [...new Set(placesArr.sort())],
      units: [...new Set(unitsArr.sort())],
    };
  }, [people]);

  return { places, units };
};
