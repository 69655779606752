import React, { useRef } from 'react';
import cn from 'classnames';

import { useAppState, useAppDispatch, SortOptions } from 'src/Context';
import { usePlacesAndUnits } from 'src/hooks/use-places-and-units';
import { useFiltered } from 'src/hooks/use-filtered';

import FiltersPopup from 'src/components/common/FiltersPopup';

import candlesSrc from 'src/static/assets/candles.svg';
import makoLogo from 'src/static/assets/mako-logo.svg';
import searchIconSrc from 'src/static/assets/search-icon.svg';

import { Person } from './Person';

import css from './MainPage.module.scss';

const PAGE_SIZE = 96;

const MainPage = () => {
  const { filters, sorting, currentPage, isFetching } = useAppState();
  const dispatch = useAppDispatch();
  const currentContainerHeight = useRef(0);
  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const { items, total } = useFiltered({ pageSize: PAGE_SIZE });
  const { units, places } = usePlacesAndUnits();

  const [isShowFilters, setIsShowFilters] = React.useState<boolean>(false);

  const [isAnimation, setIsAnimation] = React.useState<boolean>(false);
  const appRef = React.useRef<HTMLElement | null>(null);

  const observer = React.useCallback(() => {
    const height = appRef.current?.offsetHeight || 0;

    const parentHeight = window.innerHeight;
    if (height !== parentHeight && Math.abs(height - parentHeight) > 5) {
      currentContainerHeight.current = Math.max(height, currentContainerHeight.current);

      window.parent.postMessage(
        {
          payload: { iframeHeight: currentContainerHeight.current + 10 },
          sender: 'SWORDS_WIDGET',
          type: 'resize',
        },
        '*',
      );
    }
    requestAnimationFrame(observer);
  }, []);

  React.useEffect(() => {
    if (!appRef.current) {
      appRef.current = document.getElementById('root');
    }
    const rafId = requestAnimationFrame(observer);

    return () => {
      cancelAnimationFrame(rafId);
    };
  }, [observer]);

  const onSearchBtnClick = () => {
    if (!searchInputRef.current?.value) {
      return;
    }

    setIsAnimation(true);

    setTimeout(() => {
      setIsAnimation(false);
    }, 1000);

    dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 });
    dispatch({ type: 'SET_FILTERS', payload: { search: searchInputRef.current?.value || '' } });
  };

  const onPrevClick = () => {
    dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage - 1 });
    scrollTop();
  };

  const onNextClick = () => {
    dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage + 1 });
    scrollTop();
  };

  const clickOnClear = () => {
    dispatch({ type: 'SET_FILTERS', payload: { search: '' } });
    dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 });
    dispatch({ type: 'SET_SORTING', payload: SortOptions.RECENT });

    if (searchInputRef.current) searchInputRef.current.value = '';
  };

  const clickOnLogo = () => {
    dispatch({ type: 'SET_FILTERS', payload: { search: '', role: '', unit: '', place: '' } });
    dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (searchInputRef.current?.value) {
      onSearchBtnClick();
    }
  };

  const scrollTop = () => {
    window.parent.postMessage(
      {
        sender: 'SWORDS_WIDGET',
        type: 'pageChange',
      },
      '*',
    );
  };

  const onFilterBtnClick = () => {
    setIsShowFilters(true);
  };

  const clearAdvancedFilters = () => {
    dispatch({ type: 'SET_FILTERS', payload: { role: '', unit: '', place: '' } });
    dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 });
    dispatch({ type: 'SET_SORTING', payload: SortOptions.RECENT });
  };

  const addAdvancedFilters = (f: Omit<typeof filters, 'search'>) => {
    dispatch({ type: 'SET_FILTERS', payload: f });
    dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 });
    setIsAnimation(true);

    setTimeout(() => {
      setIsAnimation(false);
    }, 1000);
  };

  const onSortingClick = (sortType: SortOptions) => {
    dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 });
    dispatch({ type: 'SET_SORTING', payload: sortType });
  };

  const isNextBtnDisabled = () => currentPage * PAGE_SIZE >= total;
  const isPaginationVisible = () => total > PAGE_SIZE;
  const isSomeFiltersSelected = () => {
    const { search, ...other } = filters;

    return Object.values(other).some((f) => f !== '');
  };

  const renderItems = () => items.map((i) => <Person isAnimation={isAnimation} person={i} key={`${i.id}`} />);

  return (
    <div className={css.pageWrapper}>
      <div className={css.header}>
        <div className={css.makoLogo} onClick={clickOnLogo}>
          <img src={makoLogo} alt="מספר ההרוגים במלחמת חרבות ברזל – רשימה מתעדכנת – N12" />
        </div>
        <div className={css.candlesLogo}>
          <img src={candlesSrc} alt="הרוגי חרבות ברזל – רשימה מתעדכנת" />
        </div>
      </div>
      <div className={css.searchContainer}>
        <div className={css.searchWrapper}>
          <div className={css.inputWrapper}>
            <form action="" onSubmit={onSubmit}>
              <input ref={searchInputRef} type="search" placeholder="חיפוש" />
            </form>
            {searchInputRef.current?.value !== '' && !isFetching && (
              <div className={css.clearBtn} onClick={clickOnClear}></div>
            )}
          </div>

          <div className={cn(css.searchBtn)} onClick={onSearchBtnClick}>
            <img src={searchIconSrc} alt="search-icon" />
          </div>
        </div>
        <div className={cn(css.advancedSearchBtn, isSomeFiltersSelected() && css.active)} onClick={onFilterBtnClick}>
          סינון
        </div>
      </div>
      {items.length !== 0 && (
        <div className={css.sorting}>
          <button
            type="button"
            className={cn(css.reverseSorting, css.sortBtn, sorting === SortOptions.RECENT && css.active)}
            onClick={() => onSortingClick(SortOptions.RECENT)}
          >
            העדכני ביותר
          </button>
          <button
            type="button"
            className={cn(css.AZSorting, css.sortBtn, sorting === SortOptions.AZ && css.active)}
            onClick={() => onSortingClick(SortOptions.AZ)}
          >
            לפי א'-ב'
          </button>
        </div>
      )}
      {items.length === 0 && !isFetching && <div className={css.noSearchResults}>אין תוצאות</div>}
      {items.length !== 0 && (
        <>
          <div className={css.itemsGrid}>{renderItems()}</div>
          {isPaginationVisible() && (
            <div className={css.btns}>
              <div className={cn(css.prevBtn, currentPage === 1 && css.disabled)} onClick={onPrevClick}>
                &lsaquo; לעמוד הקודם
              </div>
              <div className={cn(css.divider, (currentPage === 1 || isNextBtnDisabled()) && css.disabled)}></div>
              <div className={cn(css.nextBtn, isNextBtnDisabled() && css.disabled)} onClick={onNextClick}>
                לעמוד הבא &rsaquo;&nbsp;&nbsp;&nbsp;
              </div>
            </div>
          )}
        </>
      )}
      {isShowFilters && (
        <FiltersPopup
          places={places}
          units={units}
          closePopup={() => setIsShowFilters(false)}
          activeFilters={filters}
          clearFilters={clearAdvancedFilters}
          setFilters={addAdvancedFilters}
        />
      )}
    </div>
  );
};

export default MainPage;
