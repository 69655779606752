import React from 'react';
import cn from 'classnames';

import { PersonInfo } from 'src/Context';
import useOnClickOutside from 'src/hooks/click-outside';

import symbol1Src from 'src/static/assets/symbol_1.svg';
import symbol2Src from 'src/static/assets/symbol_2.svg';
import symbol3Src from 'src/static/assets/symbol_3.svg';
import whatsapp from 'src/static/assets/whatsapp.svg';
import facebook from 'src/static/assets/facebook.svg';
import twitter from 'src/static/assets/twitter.svg';
import shareBtnIcon from 'src/static/assets/share-button.svg';
import fireFighterRoleIconSrc from 'src/static/assets/logo-fire-fighting.png';

import css from './Person.module.scss';

type Props = {
  isAnimation: boolean;
  className?: string;
  person: PersonInfo;
};

const getRoleImage = (role: PersonInfo['g']) => {
  if (role) {
    switch (role) {
      case 'חייל':
        return symbol1Src;
      case 'שוטר':
        return symbol2Src;
      case `שב"כ`:
        return symbol3Src;
      case 'כבאי':
        return fireFighterRoleIconSrc;
      default:
        return '';
    }
  }
};

export const Person: React.FC<Props> = ({ isAnimation, person, className = '' }) => {
  const roleImageSrc = getRoleImage(person.g);
  const sharesRef = React.useRef<HTMLDivElement>(null);
  const [isSharesVisible, setIsSharesVisible] = React.useState(false);

  const onShareToggle = () => {
    setIsSharesVisible(!isSharesVisible);
  };

  const onClickOutside = React.useCallback(() => {
    setIsSharesVisible(false);
  }, [person]);

  useOnClickOutside(sharesRef, onClickOutside);

  const onShareBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { type } = e.currentTarget.dataset;

    setIsSharesVisible(false);

    window.parent.postMessage(
      {
        payload: { person, shareType: type },
        sender: 'SWORDS_WIDGET',
        type: 'personShare',
      },
      '*',
    );
  };

  return (
    <div className={cn(css.gridItem, isAnimation && css.animation, className)}>
      <div className={css.photo}>
        <img
          src={`${person.j}` || 'https://img.mako.co.il/2023/10/10/ner.png'}
          alt={`${person.a || ''} ${person.b || ''} ${person.c || ''}`.trim()}
        />
      </div>
      <div className={css.info}>
        <div className={css.top}>
          {person.a} {person.b} {person.c}
        </div>
        <div className={css.bottom}>
          {person.i}
          {person.k && `, ${person.k}`}
        </div>
        <div className={css.description}>{person.l}</div>
      </div>
      <div className={css.sharesAndRoleImage}>
        <div className={css.icon}>{roleImageSrc && <img src={roleImageSrc} alt="symbol" />}</div>

        <div ref={sharesRef} className={css.shares}>
          <button className={css.sharesToggler} data-person-id={person.id} onClick={onShareToggle}>
            {isSharesVisible ? 'סגור' : <img src={shareBtnIcon} alt="" />}
          </button>

          <div className={cn(css.sharesList, isSharesVisible && css.visible)}>
            <button data-type="facebook" className={css.shareBtn} onClick={onShareBtnClick}>
              <img src={facebook} alt="facebook share" />
            </button>
            <button data-type="twitter" className={css.shareBtn} onClick={onShareBtnClick}>
              <img src={twitter} alt="twitter share" />
            </button>
            <button data-type="whatsapp" className={css.shareBtn} onClick={onShareBtnClick}>
              <img src={whatsapp} alt="whatsapp share" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
