import React, { createContext, useReducer, useContext } from 'react';

import { replaceFields, FIELDS_MAP } from '@api/utils/replace-fields';

import { getPeopleData } from 'src/api';

export type PersonInfo = Record<keyof typeof FIELDS_MAP, string | number>;

export enum SortOptions {
  AZ = 'az',
  RECENT = 'recent',
}

export type AdvancedFilters = {
  search: string;
  place: string;
  role: string;
  unit: string;
};

interface IState {
  candles: {
    amount: number;
  };
  people: PersonInfo[];
  normalizedPeopleData: PersonInfo[];
  currentPage: number;
  filters: AdvancedFilters;
  isFetching: boolean;
  sorting: SortOptions;
}

type Action =
  | { type: 'SET_DATA'; payload: Pick<IState, 'candles' | 'people'> }
  | { type: 'SET_FILTERS'; payload: Partial<IState['filters']> }
  | { type: 'SET_CURRENT_PAGE'; payload: IState['currentPage'] }
  | { type: 'SET_SORTING'; payload: IState['sorting'] }
  | { type: 'SET_IS_FETCHING'; payload: boolean };

const StateContext = createContext<IState | null>(null);
const DispatchContext = createContext<React.Dispatch<Action> | null>(null);

const initialState: IState = {
  candles: {
    amount: 0,
  },
  people: [],
  currentPage: 1,
  isFetching: true,
  sorting: SortOptions.RECENT,
  filters: {
    search: '',
    place: '',
    role: '',
    unit: '',
  },
  normalizedPeopleData: [],
};

function reducer(state: IState, action: Action): IState {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        candles: action.payload.candles,
        people: action.payload.people,
        normalizedPeopleData: replaceFields(action.payload.people),
      };
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload,
      };
    case 'SET_IS_FETCHING':
      return {
        ...state,
        isFetching: action.payload,
      };
    case 'SET_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    case 'SET_SORTING':
      return {
        ...state,
        sorting: action.payload,
      };

    default:
      return state;
  }
}

export function AppContextProvider(props: React.PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);

  React.useEffect(() => {
    const fetchData = async () => {
      const peopleData = await getPeopleData();

      if (peopleData) {
        dispatch({
          type: 'SET_DATA',
          payload: {
            candles: peopleData.candles,
            people: peopleData.rows,
          },
        });
      }
      dispatch({
        type: 'SET_IS_FETCHING',
        payload: false,
      });
    };

    fetchData();
  }, []);

  if (!state.people?.length) {
    // return null;
  }

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{props.children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useAppState must be used within a AppContextProvider');
  }

  return context as { [K in keyof IState]: NonNullable<IState[K]> };
}

export function useAppDispatch() {
  const dispatch = useContext(DispatchContext);

  if (!dispatch) {
    throw new Error('useAppDispatch must be used within a AppContextProvider');
  }

  return dispatch;
}
