import React from 'react';
import { styled } from '@mui/material';
import MuiAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import MuiPopper from '@mui/material/Popper';
import FormControl from '@mui/material/FormControl';

type StyledAutocompleteProps = AutocompleteProps<string[], boolean, boolean, boolean>;
const Input = styled(MuiAutocomplete, {
  label: 'AutocompleteInput',
})<StyledAutocompleteProps>(({ theme, value }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: '#fff',
    border: `1px solid rgba(161, 170, 195, 1)`,
    borderRadius: '2px',
    padding: '13px 15px 13px!important',
    minHeight: '51px',
    boxSize: 'border-box',

    '& input': {
      fontFamily: 'Open Sans',
      fontSize: '16px',
      color: 'rgba(3, 19, 41, 1)',
      padding: '0 !important',
      lineHeight: '18px',
      minHeight: '0',
    },

    '& fieldset': {
      border: 'none',
    },

    '& .MuiAutocomplete-endAdornment': {
      right: 'auto !important',
      left: '0.3rem',
    },
  },

  '& .MuiAutocomplete-popupIndicator': {
    color: 'rgba(3, 17, 40, 1)',
  },
})) as typeof MuiAutocomplete;

const Popper = styled(MuiPopper, { label: 'CustomPopper' })(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: '0px',
    borderRadius: '2px',
    backgroundColor: '#fff',
    border: `1px solid rgba(161, 170, 195, 1)`,

    '& .MuiAutocomplete-option': {
      padding: '12px 0',
      fontSize: '16px',
      lineHeight: '18px',
      color: 'rgba(3, 19, 41, 1)',
      paddingRight: '15px',
      fontFamily: 'Open Sans',
      borderBottom: '1px solid rgba(161, 170, 195, 1)',

      '&:lasy-child': {
        borderBottom: 'none',
      },

      '&:hover': {
        backgroundColor: `#fff !important`,
      },

      '&[aria-selected="true"]': {
        backgroundColor: `#fff !important`,
      },
    },
    '& .MuiAutocomplete-listbox': {
      padding: '0 !important',
    },
  },
}));

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  onSelectChange: (val: string) => void;
  value: any;
}

const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(
  props: Props<T, Multiple, DisableClearable, FreeSolo>,
) => {
  const { value, onSelectChange, ...autocompleteInputProps } = props;

  const onChange = (event: React.BaseSyntheticEvent, value: any) => {
    onSelectChange(value);
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <Input
        {...autocompleteInputProps}
        value={value}
        PopperComponent={Popper}
        onChange={onChange}
        noOptionsText="אין תוצאות"
      />
    </FormControl>
  );
};

export default Autocomplete;
