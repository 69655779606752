export const getPeopleData = async (): Promise<any> => {
  try {
    const CONFIG_URL =
      process.env.ENV === 'production'
        ? `https://makoironcdn.cdn-il.com/website%2Fdata.json?v=${5}`
        : `https://storage.googleapis.com/${process.env.PROJECT_ID}.appspot.com/website%2Fdata.json?v=${Date.now()}`;

    const response = await fetch(CONFIG_URL, { method: 'GET' });
    const result = await response.json();

    return result;
  } catch (e) {
    console.error(e);
    return null;
  }
};
